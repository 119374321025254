import React, { Dispatch, useCallback, useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { Context } from "../../home.page";
import instance from "../../../api/api";
import Help from "../../../components/_helper/help.component";
import { setCurrentPage } from "../../../store/actionCreators";
import { useTranslation } from "react-i18next";

/**
 * /settings/notifications page
 * 
 */

export const defaultPushNotifications = ["STORY_COAUTHOR_INVITE", "PENDING_RELATION_NEW", "COMMENT_ON_MEDIA", "STORY_PUBLISHED", "COMMENT_ON_STORY", "REQUEST_NEW", "STORY_UPDATED", "STORY_ANNIVERSARY"];

function NotificationSettings() {

    const userDetails = useContext(Context).user; 
    
    const [ coAuthorInvitations, setCoAuthorInvitations ] = useState<boolean>( (userDetails.settings && userDetails.settings.pushn && userDetails.settings.pushn.includes("STORY_COAUTHOR_INVITE")) ? true : false);
    const [ contactRequests, setContactRequests ] = useState<boolean>( (userDetails.settings && userDetails.settings.pushn && userDetails.settings.pushn.includes("PENDING_RELATION_NEW")) ? true : false);
    const [ mediaComments, setMediaComments ] = useState<boolean>( (userDetails.settings && userDetails.settings.pushn && userDetails.settings.pushn.includes("COMMENT_ON_MEDIA")) ? true : false);
    const [ newStories, setNewStories ] = useState<boolean>( (userDetails.settings && userDetails.settings.pushn && userDetails.settings.pushn.includes("STORY_PUBLISHED")) ? true : false);
    const [ storyComments, setStoryComments ] = useState<boolean>( (userDetails.settings && userDetails.settings.pushn && userDetails.settings.pushn.includes("COMMENT_ON_STORY")) ? true : false);
    const [ storyRequests, setStoryRequests ] = useState<boolean>( (userDetails.settings && userDetails.settings.pushn && userDetails.settings.pushn.includes("REQUEST_NEW")) ? true : false);
    const [ updatedStories, setUpdatedStories ] = useState<boolean>( (userDetails.settings && userDetails.settings.pushn && userDetails.settings.pushn.includes("STORY_UPDATED")) ? true : false);
    const [ storyAnniversary, setStoryAnniversary ] = useState<boolean>( (userDetails.settings && userDetails.settings.pushn && userDetails.settings.pushn.includes("STORY_ANNIVERSARY")) ? true : false);

    const dispatch: Dispatch<any> = useDispatch();
    const setPageName = useCallback((currentPage: string) => dispatch(setCurrentPage(currentPage)), [dispatch]);

    const updateLoggedInUserData = useContext(Context).setUserDetails;

    const { t } = useTranslation();    

    useEffect(() => {
        setPageName(t("Notifications"));
    }, [])

    useEffect(() => {
        // run update only if anything changes       
        if (userDetails.settings && userDetails.settings.pushn && (
                (userDetails.settings.pushn.includes("STORY_COAUTHOR_INVITE") && !coAuthorInvitations)
                || (!userDetails.settings.pushn.includes("STORY_COAUTHOR_INVITE") && coAuthorInvitations)
                )
            ) {
            updateUserData("STORY_COAUTHOR_INVITE");
        }
    }, [coAuthorInvitations]); 

    useEffect(() => {
        // run update only if anything changes   
        if (userDetails.settings && userDetails.settings.pushn && (
                (userDetails.settings.pushn.includes("PENDING_RELATION_NEW") && !contactRequests)
                || (!userDetails.settings.pushn.includes("PENDING_RELATION_NEW") && contactRequests)
                )
            ) {
            updateUserData("PENDING_RELATION_NEW");
        }            
    }, [contactRequests]);

    useEffect(() => {
        // run update only if anything changes   
        if (userDetails.settings && userDetails.settings.pushn && (
                (userDetails.settings.pushn.includes("COMMENT_ON_MEDIA") && !mediaComments)
                || (!userDetails.settings.pushn.includes("COMMENT_ON_MEDIA") && mediaComments)
                )
            ) {
            updateUserData("COMMENT_ON_MEDIA");
        }            
    }, [mediaComments]);

    useEffect(() => {
        // run update only if anything changes   
        if (userDetails.settings && userDetails.settings.pushn && (
                (userDetails.settings.pushn.includes("STORY_PUBLISHED") && !newStories)
                || (!userDetails.settings.pushn.includes("STORY_PUBLISHED") && newStories)
                )
            ) {
            updateUserData("STORY_PUBLISHED");
        }            
    }, [newStories]);

    useEffect(() => {
        // run update only if anything changes   
        if (userDetails.settings && userDetails.settings.pushn && (
                (userDetails.settings.pushn.includes("STORY_ANNIVERSARY") && !storyAnniversary)
                || (!userDetails.settings.pushn.includes("STORY_ANNIVERSARY") && storyAnniversary)
                )
            ) {
            updateUserData("STORY_ANNIVERSARY");
        }            
    }, [storyAnniversary]);    
    
    useEffect(() => {
        // run update only if anything changes   
        if (userDetails.settings && userDetails.settings.pushn && (
                (userDetails.settings.pushn.includes("COMMENT_ON_STORY") && !storyComments)
                || (!userDetails.settings.pushn.includes("COMMENT_ON_STORY") && storyComments)
                )
            ) {
            updateUserData("COMMENT_ON_STORY");
        }            
    }, [storyComments]);
    
    useEffect(() => {
        // run update only if anything changes   
        if (userDetails.settings && userDetails.settings.pushn && (
                (userDetails.settings.pushn.includes("REQUEST_NEW") && !storyRequests)
                || (!userDetails.settings.pushn.includes("REQUEST_NEW") && storyRequests)
                )
            ) {
            updateUserData("REQUEST_NEW");
        }            
    }, [storyRequests]);

    useEffect(() => {
        // run update only if anything changes   
        if (userDetails.settings && userDetails.settings.pushn && (
                (userDetails.settings.pushn.includes("STORY_UPDATED") && !updatedStories)
                || (!userDetails.settings.pushn.includes("STORY_UPDATED") && updatedStories)
                )
            ) {
            updateUserData("STORY_UPDATED");
        }            
    }, [updatedStories]);

    async function updateUserData(input:string) {

        let pushn = userDetails.settings.pushn;

        let details = {};

        if(!pushn) {

            details = {
                settings: {...userDetails.settings,
                    pushn: defaultPushNotifications,
                },
            }            

        } else {

            pushn = pushn.includes(input) ? pushn.filter(item => item !== input) : [...pushn, input];

            details = {
                settings: {...userDetails.settings,
                    pushn: pushn,
                },
            }            

        }

        try {
            const response = await instance.put('/user/' + localStorage.userid, {details:details});
            updateLoggedInUserData(response.data);
        }
        catch(error) {
            console.log('error', error)
        }
    }    

    return (
        <div className="settings push-notifications details-container">
            <div className="settings-container-newsletters">
            <div className="setting-group-row">
                <p className="setting-group-title">{t("Push Notifications")}</p>
            </div>
            {(userDetails.settings && userDetails.settings.updates) ? 
            <div className="update-container">
                    <div className="monthly-update setting-option-row notifications-row switch-container">
                        <span>{t("Co-author invitations")}</span>
                        <p className="switch-status">{coAuthorInvitations ? t("On") : t("Off")}</p>
                        <Switch className={`switch ${coAuthorInvitations ? "active-gold-switch" : ""}`} 
                                offColor="#17243d"
                                offHandleColor="#8B919E"
                                onColor="#006238"
                                onHandleColor="#006238"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={14}
                                width={32}
                                onChange={(checked) => setCoAuthorInvitations(checked)} 
                                checked={coAuthorInvitations} />
                    </div>
                    <div className="monthly-update setting-option-row notifications-row switch-container">
                        <span>{t("Contacts requests")}</span>
                        <p className="switch-status">{contactRequests ? t("On") : t("Off")}</p>
                        <Switch className={`switch ${contactRequests ? "active-gold-switch" : ""}`} 
                                offColor="#17243d"
                                offHandleColor="#8B919E"
                                onColor="#006238"
                                onHandleColor="#006238"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={14}
                                width={32}
                                onChange={(checked) => setContactRequests(checked)} 
                                checked={contactRequests} />
                    </div>
                    <div className="monthly-update setting-option-row notifications-row switch-container">
                        <span>{t("Media comments")}</span>
                        <p className="switch-status">{mediaComments ? t("On") : t("Off")}</p>
                        <Switch className={`switch ${mediaComments ? "active-gold-switch" : ""}`} 
                                offColor="#17243d"
                                offHandleColor="#8B919E"
                                onColor="#006238"
                                onHandleColor="#006238"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={14}
                                width={32}
                                onChange={(checked) => setMediaComments(checked)} 
                                checked={mediaComments} />
                    </div>
                    <div className="monthly-update setting-option-row notifications-row switch-container">
                        <span>{t("New stories")}</span>
                        <p className="switch-status">{newStories ? t("On") : t("Off")}</p>
                        <Switch className={`switch ${newStories ? "active-gold-switch" : ""}`} 
                                offColor="#17243d"
                                offHandleColor="#8B919E"
                                onColor="#006238"
                                onHandleColor="#006238"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={14}
                                width={32}
                                onChange={(checked) => setNewStories(checked)} 
                                checked={newStories} />
                    </div>
                    <div className="monthly-update setting-option-row notifications-row switch-container">
                        <span>{t("Story anniversaries")}</span>
                        <p className="switch-status">{storyAnniversary ? t("On") : t("Off")}</p>
                        <Switch className={`switch ${storyAnniversary ? "active-gold-switch" : ""}`} 
                                offColor="#17243d"
                                offHandleColor="#8B919E"
                                onColor="#006238"
                                onHandleColor="#006238"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={14}
                                width={32}
                                onChange={(checked) => setStoryAnniversary(checked)} 
                                checked={storyAnniversary} />
                    </div>                    
                    <div className="monthly-update setting-option-row notifications-row switch-container">
                        <span>{t("Story comments")}</span>
                        <p className="switch-status">{storyComments ? t("On") : t("Off")}</p>
                        <Switch className={`switch ${storyComments ? "active-gold-switch" : ""}`} 
                                offColor="#17243d"
                                offHandleColor="#8B919E"
                                onColor="#006238"
                                onHandleColor="#006238"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={14}
                                width={32}
                                onChange={(checked) => setStoryComments(checked)} 
                                checked={storyComments} />
                    </div>
                    <div className="monthly-update setting-option-row notifications-row switch-container">
                        <span>{t("Story requests")}</span>
                        <p className="switch-status">{storyRequests ? t("On") : t("Off")}</p>
                        <Switch className={`switch ${storyRequests ? "active-gold-switch" : ""}`} 
                                offColor="#17243d"
                                offHandleColor="#8B919E"
                                onColor="#006238"
                                onHandleColor="#006238"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={14}
                                width={32}
                                onChange={(checked) => setStoryRequests(checked)} 
                                checked={storyRequests} />
                    </div>
                    <div className="monthly-update setting-option-row notifications-row switch-container">
                        <span>{t("Updated stories")}</span>
                        <p className="switch-status">{updatedStories ? t("On") : t("Off")}</p>
                        <Switch className={`switch ${updatedStories ? "active-gold-switch" : ""}`} 
                                offColor="#17243d"
                                offHandleColor="#8B919E"
                                onColor="#006238"
                                onHandleColor="#006238"
                                handleDiameter={20}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={14}
                                width={32}
                                onChange={(checked) => setUpdatedStories(checked)} 
                                checked={updatedStories} />
                    </div>                                                                                                            
                </div>    
    : null}     
            </div>
            <Help category={"SETTINGS"} pageName={"Notifications"}></Help>  
        </div>
    )
}

export default NotificationSettings
